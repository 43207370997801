import React from 'react'
const style = {
    padding: '20px',
    textAlign: 'center'
}
const Footer = props => {
    return (
        <div style={style}>
            <a href="https://beian.miit.gov.cn/">
                Copyright© 2021 imcoding.com  粤ICP备 20023632号
            </a>
        </div>
    )
}

export default Footer;