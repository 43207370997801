/*
 * @Author:
 * @Date: 2020-07-15 16:22:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-08 10:34:04
 * @Description:所有的请求统一管理
 */

import request from "/pages/api/request.js";

export async function login(params) {
  return request("/sys/login", {
    method: "POST",
    data: params
  });
}

/* ---------------------------------------------------------------------标签---------------------------------------------------------------------- */
// 标签列表
export async function tagsList() {
  return request("/blog/tags/list", {
    method: "get",
  });
}

// 文章列表(默认)
export async function getArticleList(params) {
  let _params = [];
  Object.keys(params).forEach(key => {
    _params.push(`${key}=${params[key]}`);
  });
  // 只查询已上架的文章
  _params.push('articleStatus=1');
  return request(`/blog/article/list?${_params.join('&')}`, {
    method: "POST",
    data: params
  });
}

// 文章列表(默认)
export async function getArticleListSearch(params) {
  let _params = [];
  Object.keys(params).forEach(key => {
    _params.push(`${key}=${params[key]}`);
  });
  _params.push('articleStatus=1');
  return request(`/blog/article/search?${encodeURI(_params.join('&'))}`, {
    method: "POST"
  });
}

// 修改文章
export async function updateArticle(data) {
  return request("/blog/article/edit", {
    method: "POST",
    data: data
  });
}

// 发布文章
export async function addArticle(data) {
  return request("/blog/article/add", {
    method: "POST",
    data: data
  })
}

// 删除文章
export async function delArticle(id) {
  return request("/blog/article/del?id=" + id, {
    method: "POST",
  })
}

// 微信类
export async function getSignature(url) {
  return request(`wx/signature?url=${url}`, {
    method: "get",
  })
}
