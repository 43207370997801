import React from 'react';
import {Drawer, Space} from "antd";
import {
    RollbackOutlined
} from '@ant-design/icons';
import styles from './css/DrawerMenu.module.scss'
import Image from "next/image";

const DrawerMenu = props => {
    const {visible, onClose} = props;
    const buttonGroup = [
        {title: '首页', fn: '', icon: ''},
        {title: '文章分类', fn: '', icon: ''},
        {title: '关于作者', fn: '', icon: ''}
    ]
    const titleNode = (
        <>
            <div onClick={() => onClose()} className={styles.drawerTitle}>
                <RollbackOutlined />
                <span className={styles.drawerTitle.span}>返回上一级</span>
            </div>
        </>
    )

    return (
        <>
            <Drawer
                title={titleNode}
                placement="right"
                closable={false}
                onClose={() => onClose()}
                visible={visible}
                width={"100%"}
                className="myDrawer"
            >
                <div>
                    {
                        buttonGroup.map((item, index) => (
                            <div key={index} className={styles.btnGroup}>
                                <button className={styles.ylBtn}>{item.title}</button>
                            </div>
                        ))
                    }
                </div>
            </Drawer>
        </>
    )
}

export default DrawerMenu;