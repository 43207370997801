/*
 * @Author: Parker
 * @Date: 2020-07-15 15:06:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-14 15:14:30
 * @Description: axios 封装
 */
import axios from "axios";
import { BASE_URL} from "./url";


const request = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const { status } = response.data;
    if (!status) {
      return response.data;
    } else {
      return {};
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
