import React, {useState, useEffect} from 'react';
import {tagsList} from "../../../modules/api";
import _logo from '../../../public/img/classify/icon_code.png'
import {useRouter} from "next/router";

const MyColumn =  (props) => {
    const [myCol, setMyCol] = useState([]);
    const router = useRouter()

    const DescribeTags = () => {
        tagsList().then(res => {
            const {code, data} = res;
            if (code === 200) {
                setMyCol(data)
            }
        })
    }

    const handleCheckTag = tagName => {
        router.push({pathname: '/SearchResults', query: {classify: tagName || 'others'}})
    }

    useEffect(() => {
        DescribeTags();
    }, [])
    return (
        <>
            <div className="border-b border-gray-200 pb-1">我的专栏：</div>
            <div className="pt-1">
                {
                    myCol.map((item, index) => (
                        <div className="flex justify-between cursor-pointer hover:text-blue-500 transition ease-in duration-500 my-4" key={index} onClick={() => handleCheckTag(item.tagName)}>
                            <div className="flex items-center">
                                <img className="w-5 mr-2" src={item.tagIcon || _logo.src} alt=""/>
                                <span className="">{item.tagName || '其他'}</span>
                            </div>
                            <span>{item.articleCount}篇</span>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default MyColumn;