import React, {useState} from 'react';
import Image from 'next/image'
import {Popover} from 'antd';
import Link from "next/link";
import styles from '../../styles/RigInfos.module.scss'

const svgs = {
    zy: require('../../public/svg/zy.svg').default.src,
    home: require('../../public/svg/home.svg').default.src,
    jishu: require('../../public/svg/jishu.svg').default.src,
    email: require('../../public/svg/email.svg').default.src,
    github: require('../../public/svg/github.svg').default.src,
    gitee: require('../../public/svg/gitee.svg').default.src,
    wechat: require('../../public/svg/wechat.svg').default.src,
};

const weCode = require('!!file-loader?name=[name].[ext]!../../public/img/my_weCode.jpg')
import MyColumn from "./components/MyColumn";

const myInfo = [
    {
        desc: 'web前端开发',
        icon: svgs.zy
    },
    {
        desc: 'React, Vue, Css, HTML, JavaScript',
        icon: svgs.jishu
    },
    {
        desc: '深圳',
        icon: svgs.home
    },
    {
        desc: '1437477446@qq.com',
        icon: svgs.email
    }
];

const myWeCode = (
    <img src={weCode.src} className="w-44" alt=""/>
);

export default function RigInfos(props) {
    const [tags, setTags] = useState([
        'Vue', 'React', 'JS', 'JAVA', 'MySQL'
    ]);
    return (
        <>
            <div className="rounded-md bg-white sticky top-20">
                <div className="p-4">
                    <div>
                        <div className="text-center">
                            <Image
                                   width={60}
                                   height={60}
                                   className="rounded-full"
                                 src={require('/public/img/header.png')} alt=""/>
                        </div>
                        <div className="text-center">
                            <span className="font-semibold text-xl pt-2">Parker</span>
                            <span className="block text-xs mt-2 text-gray-500 hover:text-blue-500 transition ease-in duration-500">今天学习不狠，明天地位不稳！</span>
                            <div className="justify-center flex pt-4">
                                <Link href="https://github.com/ParkerTzh">
                                    <img src={svgs.github} alt="" className="w-5 mr-2 cursor-pointer"/>
                                </Link>
                                <Link href="https://gitee.com/ParkerT">
                                    <img src={svgs.gitee} alt="" className="w-5 mr-2 cursor-pointer"/>
                                </Link>
                                <Popover content={myWeCode} title="来和作者扯犊子" placement="bottom">
                                    <img src={svgs.wechat} alt="" className="w-5 cursor-pointer"/>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="mt-7">
                        {
                            myInfo.map((item, index) => (
                                <div className="grid grid-cols-8 items-stretch pt-2 text-md" key={index}>
                                    <img src={item.icon} alt="" className="w-4"/>
                                    <span className="col-span-7">{item.desc}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="mt-7">
                        <MyColumn />
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}