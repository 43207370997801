import React, {useEffect, useState} from 'react';
import { useRouter } from 'next/router';
import {Input} from 'antd';
import Link from "next/link";
import styles from './css/Mobile_Header.module.scss'
import {
    MenuOutlined
} from '@ant-design/icons';
import DrawerMenu from "./components/DrawerMenu";

const { Search } = Input;


const MobileHeader = () => {
    const router = useRouter();
    const [visible, setVisible] = useState(false);

    const handlerSearch = value => {
        if (value) {
            router.push({pathname: '/SearchResults', query: {keyword: value}})
        } else {
        }
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        return() => {
            // emitter.removeListener('searchHandler');
        }
    }, [])
    return (
        <>
            <div className="fixed flex text-center shadow-lg z-30 h-16 inset-x-0 top-0 h-18 bg-gradient-to-r bg-white to-pink-500">
                <div className="flex items-center">
                    <div className="w-32 pl-1.5">
                        <Link href="/">
                        <span className={styles.MLogo}>
                            {/*&lt;&#47;&gt;*/}
                            Parker Blogs
                        </span>
                        </Link>
                    </div>
                    <div className="px-2.5">
                        <Search className="search-c w-full"
                                placeholder="input search text"
                                onSearch={handlerSearch}
                                enterButton />
                    </div>
                    <div className="w-16" onClick={showDrawer}>
                        <MenuOutlined style={{fontSize: '20px'}}/>
                    </div>
                </div>
            </div>
            <DrawerMenu onClose={onClose} visible={visible}/>
        </>
    )
}
export default MobileHeader;
