import React, {useEffect, useState} from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {Input} from 'antd';
import logo from '../public/img/logo.png';
import Link from "next/link";
const { Search } = Input;


const Header = props => {
    const router = useRouter()
    const [searchLoading, setSearchLoading] = useState(false);
    const [scroll, setScroll] = useState(0);
    const handlerSearch = value => {
        console.log('router', router)
        if (router.pathname.indexOf('/SearchResults') > -1) {
            setSearchLoading(true);
            setTimeout(() => {
                setSearchLoading(false);
            }, 3000)
        } else {
            setSearchLoading(true);
        }
        if (value) {
            router.push({pathname: '/SearchResults', query: {keyword: value}})
        } else {
        }
    }
    useEffect(() => {
        window.onscroll = function() {
            //为了保证兼容性，这里取两个值，哪个有值取哪一个
            //scrollTop就是触发滚轮事件时滚轮的高度
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            setScroll(scrollTop);
        }
        return() => {
            // emitter.removeListener('searchHandler');
        }
    })
    return (
        <div className={`fixed z-30 overflow-hidden inset-x-0 top-0 grid grid-cols-6 h-18 sm:grid-cols-6 gap-1 bg-gradient-to-r from-orange-400 bg-white shadow-sm to-pink-500 shadow-sm md:px-20 ${scroll > 20 && ' shadow-md'}`}>
            <div className="md:col-start-2 xs:col-start-0 col-span-4">
                <div className="grid md:grid-cols-11 xs:grid-cols-1 gap-1 justify-items-auto">
                    <div className="col-span-2 h-16">
                        <Image src={logo} alt="" className="logo" layout="intrinsic"/>
                    </div>
                    <div className="flex items-center text-xl font-medium">
                        <Link href="/" className="col-span-1">
                            首页
                        </Link>
                    </div>
                    <div className="col-span-1 flex items-center text-xl font-medium">
                        <Link href="/">
                            <span>分类</span>
                        </Link>
                    </div>
                    <div className="col-span-7 flex items-center justify-end">
                        <Search className="search-c w-2/5"
                                placeholder="文章关键词检索"
                                onSearch={handlerSearch}
                                enterButton
                                loading={searchLoading}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;
