import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import MobileHeader from "./Mobile/Mobile_Header";

const _style = {
    layout: {
        background: "#f4f5f5"
    }
}

export default function Layout(props) {
    const { children } = props;

    return (
        <div className="layout" style={_style.layout}>
            <div className="block sm:hidden">
                <MobileHeader />
            </div>
            <div className="hidden sm:block">
                <Header />
            </div>
            <div className="grid grid-cols-6 sm:grid-cols-6 gap-4 md:pt-20 md:p-16 pt-14 px-0">
                <div className="md:col-start-2 md:col-span-4 col-span-6 col-start-0 w-full">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}